import { get } from "lodash";
import {
  level1Img,
  level2Img,
  level3Img,
  level4Img,
  level5Img,
  level6Img,
  level7Img,
  level0Img,
} from "../images";

export function rankingImage(rank) {
  let ranking = get(rank, "level");
  switch (ranking) {
    case 1:
      return level1Img; //orange
    //   break;
    case 2:
      return level2Img; //  blue
    //   break;
    case 3:
      return level3Img; // purple
    //   break;
    case 4:
      return level4Img; // brown
    //   break;
    case 5:
      return level5Img; // yellow
    //   break;
    case 6:
      return level6Img; // red
    //   break;
    case 7:
      return level7Img; // green
    //   break;
    default:
      return level0Img; // turquoise (cyan)
    //   break;
  }
}
