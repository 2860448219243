import { isEmpty } from "@antv/util";
import { Spin } from "antd";
import { map, set, sortBy } from "lodash";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ProgressBar, Step } from "react-step-progress-bar";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getPairingUser from "../../../newApi/pairing/getPairingUser";
import getPlacements from "../../../newApi/placement/getPlacements";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import "../../../styles/progress-bar.css";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { rankingImage } from "../../../utilities/pairing";
import { numberDecimal } from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import "react-step-progress-bar/styles.css";

// import ReactSpeedometer from "react-d3-speedometer";

const PAGESIZE = 10;
// markup
const NewsAndVideo = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const [languageType, setLanguageType] = useState("all");
  const [dataSource, setDataSource] = useState({});
  const [fullPlacements, setFullPlacements] = useState([]);
  const [selectedPlacement, setSelectedPlacement] = useState({});
  const [updatedUser, setUpdatedUser] = useState({});
  const accessKey = get(props.user, "accessKey");

  let user = get(props, "user.user");
  // console.log(props);
  // console.log(user);

  useEffect(() => {
    let profile = get(user, "profile");
    // console.log(profile);
    if (!isEmpty(profile)) {
      setLoading(true);

      getPlacements(7, 0, {
        userId: get(user, "pairingUser._id") || "",
      })
        .then((res) => {
          // console.log(res);
          let placement_data = sortBy(get(res, "data"), ["username"]);
          setFullPlacements(placement_data);
          setSelectedPlacement(get(placement_data, `[0]`));

          setLoading(false);
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
        });
    }
    getVipCap();
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedPlacement)) {
      let staticUsed = get(selectedPlacement, "usedStaticCap");
      let staticTotal = get(selectedPlacement, "staticCap");
      let dynamicUsed = get(selectedPlacement, "usedDynamicCap");
      let dynamicTotal = get(selectedPlacement, "dynamicCap");

      let tempSource = {};
      let staticData = {
        used: staticUsed,
        total: staticTotal,
        percent: (staticUsed / staticTotal) * 100,
      };
      let dynamicData = {
        used: dynamicUsed,
        total: dynamicTotal,
        percent: (dynamicUsed / dynamicTotal) * 100,
      };

      set(tempSource, "staticData", staticData);
      set(tempSource, "dynamicData", dynamicData);

      // console.log(tempSource);
      setDataSource(tempSource);
    }
  }, [selectedPlacement]);

  function getVipCap() {
    getPairingUser(
      1,
      0,
      {
        _id: get(user, "pairingUser._id"),
      },
      accessKey
    ).then((res) => {
      setUpdatedUser(get(res, "data[0]"));
    });
  }

  const capTypes = [
    {
      // title: "static",
      title: t("static", sourceKey.pairing),
      // text: "Static",
      text: t("static", sourceKey.pairing),
      textCn: "静态",
      used: get(dataSource, "staticData.used") || 0,
      total: get(dataSource, "staticData.total") || 0,
      percentage: get(dataSource, "staticData.percent") || 0,
      // percentage: 2.25,
      path: routes.staticCap.to(),
      highVolumeColor: "yellow",
      lowVolumeColor: "green",
    },
    {
      // title: "dynamic",
      title: t("dynamic", sourceKey.pairing),
      // text: "Dynamic",
      text: t("dynamic", sourceKey.pairing),
      textCn: "动态",
      used: get(dataSource, "dynamicData.used") || 0,
      total: get(dataSource, "dynamicData.total") || 0,
      percentage: get(dataSource, "dynamicData.percent") || 0,
      // percentage: 49.25,
      path: routes.dynamicCap.to(),
      highVolumeColor: "red",
      lowVolumeColor: "darkblue",
    },
    {
      // title: "vip",
      title: t("vip", sourceKey.pairing),
      // text: "VIP",
      text: t("vip", sourceKey.pairing),
      textCn: "",
      used: get(updatedUser, "usedVipCap") || 0,
      total: get(updatedUser, "vipCap") || 0,
      percentage:
        (get(updatedUser, "usedVipCap") / get(updatedUser, "vipCap")) * 100 ||
        0,
      // percentage: 0.25,
      path: routes.vipCap.to(),
      highVolumeColor: "orange",
      lowVolumeColor: "skyblue",
    },
  ];
  const bottomCap = [
    {
      // title: "vip",
      title: t("vip", sourceKey.pairing),
      // text: "VIP",
      text: t("vip", sourceKey.pairing),
      textCn: "",
      used: get(updatedUser, "usedVipCap") || 0,
      total: get(updatedUser, "vipCap") || 0,
      percentage:
        (get(updatedUser, "usedVipCap") / get(updatedUser, "vipCap")) * 100 ||
        0,
      // percentage: 0.25,
      path: routes.vipCap.to(),
      highVolumeColor: "orange",
      lowVolumeColor: "skyblue",
    },
  ];
  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack={true}
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {/* 收益容量  */}
                  {t("revenueCapacity", sourceKey.pairing)}
                </div>
              </React.Fragment>
            }
          >
            <div className="pt-2 mx-4">
              <div className="grid grid-cols-12 pt-2 gap-4">
                {map(fullPlacements, (item, index) => {
                  let colSpan = 2;
                  let indexOfValue = 0;

                  if (index <= 2) {
                    colSpan = 4;
                    return (
                      <div className={` col-span-4`}>
                        <div
                          className={`${get(item, "username") ===
                              get(selectedPlacement, "username")
                              ? "bg-main-color-gradient"
                              : indexOfValue === -1
                                ? " "
                                : "touchbox-bg-color"
                            }   rounded-lg py-2 text-base font-semibold`}
                          style={{
                            height: "100%",
                            cursor: indexOfValue !== -1 ? "pointer" : "",
                          }}
                          onClick={() => {
                            if (indexOfValue !== -1) {
                              setSelectedPlacement(item);
                            }
                          }}
                        >
                          <div
                            className="flex justify-center"
                            style={{
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            {get(item, "username")}
                          </div>
                          <div className="flex justify-center pt-1">
                            <img
                              src={rankingImage(get(item, "ranking"))}
                              style={{ width: "53px", height: "38.16px" }}
                            />
                          </div>

                          {/* {item.username} */}

                          {/* <span
                            className={`${
                              get(item, "username") ===
                              get(selectedPlacement, "username")
                                ? "bg-main-color-gradient"
                                : indexOfValue === -1
                                ? " "
                                : "touchbox-bg-color"
                            }   rounded-lg py-2 px-6 flex justify-center text-base font-semibold`}
                            // style={{ width: "20%" }}
                          >
                            {index + 1}
                          </span> */}
                          {/* <span
                            className={`${
                              get(item, "username") ===
                              get(selectedPlacement, "username")
                                ? "bg-main-color-gradient"
                                : indexOfValue === -1
                                ? " "
                                : "touchbox-bg-color"
                            }   rounded-lg py-2 px-6 flex justify-center text-base font-semibold`}
                            // style={{ width: "20%" }}
                          >
                            {index + 1}
                          </span> */}
                        </div>
                      </div>
                    );
                  } else if (index > 2) {
                    colSpan = 3;
                    return (
                      <div className={` col-span-3`}>
                        <div
                          className={`${get(item, "username") ===
                              get(selectedPlacement, "username")
                              ? "bg-main-color-gradient"
                              : indexOfValue === -1
                                ? " "
                                : "touchbox-bg-color"
                            }   rounded-lg py-2 text-base font-semibold`}
                          style={{
                            height: "100%",
                            cursor: indexOfValue !== -1 ? "pointer" : "",
                          }}
                          onClick={() => {
                            if (indexOfValue !== -1) {
                              setSelectedPlacement(item);
                            }
                          }}
                        >
                          <div
                            className="flex justify-center"
                            style={{
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            {get(item, "username")}
                          </div>
                          <div className="flex justify-center pt-1">
                            <img
                              src={rankingImage(get(item, "ranking"))}
                              style={{ width: "53px", height: "38.16px" }}
                            />
                          </div>

                          {/* {item.username} */}

                          {/* <span
                            className={`${
                              get(item, "username") ===
                              get(selectedPlacement, "username")
                                ? "bg-main-color-gradient"
                                : indexOfValue === -1
                                ? " "
                                : "touchbox-bg-color"
                            }   rounded-lg py-2 px-6 flex justify-center text-base font-semibold`}
                            // style={{ width: "20%" }}
                          >
                            {index + 1}
                          </span> */}
                          {/* <span
                            className={`${
                              get(item, "username") ===
                              get(selectedPlacement, "username")
                                ? "bg-main-color-gradient"
                                : indexOfValue === -1
                                ? " "
                                : "touchbox-bg-color"
                            }   rounded-lg py-2 px-6 flex justify-center text-base font-semibold`}
                            // style={{ width: "20%" }}
                          >
                            {index + 1}
                          </span> */}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              {/* <div className="grid grid-cols-6 pt-2">
                {map(capTypes, (item, index) => {
                  let pValue = parseFloat(
                    formatDecimalNumber(get(item, "percentage"), 2)
                  );
                  let arrayData = [0, pValue, 100];

                  return (
                    <div
                      className="col-span-2 mx-5 px-2 rounded-2xl flex justify-center"
                      // onClick={() => {
                      //   navigate(get(item, "path"));
                      // }}
                    >
                      <div>
                        <div className="pt-2 " style={{ width: "100%" }}>
                          <div className=" text-lg font-semibold flex justify-center items-center">
                            {get(item, "text")}
                          </div>
                        </div>

                        <div
                          className={`${
                            get(item, "percentage") <= 50
                              ? get(item, "lowVolumeColor")
                              : get(item, "highVolumeColor")
                          } px-2  pb-4`}
                        >
                          <div className="progress">
                            <div className="inner">
                              <div className="percent">
                                <span>
                                  {formatDecimalNumber(
                                    get(item, "percentage"),
                                    2
                                  )}
                                </span>
                                %
                              </div>
                              <div
                                className="water"
                                style={{
                                  top: `${
                                    100 -
                                    formatDecimalNumber(
                                      get(item, "percentage"),
                                      2
                                    )
                                  }%`,
                                }}
                              ></div>
                              <div className="glare"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> */}
              <div className="mt-4">
                {map(capTypes, (item, index) => {
                  let percentage = formatDecimalNumber(
                    get(item, "percentage"),
                    2
                  );
                  return (
                    <div className="mb-4">
                      <div className="flex justify-between py-2">
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          {get(item, "text")}
                        </div>
                        <div
                          style={{
                            fontSize: "10px",
                            fontWeight: 500,
                          }}
                        >
                          {formatDecimalNumber(
                            get(item, "used"),
                            numberDecimal
                          )}{" "}
                          /{" "}
                          {formatDecimalNumber(
                            get(item, "total"),
                            numberDecimal
                          )}
                        </div>
                      </div>
                      <div>
                        <ProgressBar
                          filledBackground="linear-gradient(267.54deg, #FF0006 0.38%, #690003 95.9%)"
                          unfilledBackground="#A5A5A5"
                          percent={percentage}
                          stepPositions={[percentage]}
                          height="11px"
                        >
                          <Step transition="scale">
                            {({ accomplished, index }) => (
                              <div
                                className={`relative transitionStep ${accomplished ? "" : null
                                  }`}
                              >
                                <div
                                  className="absolute "
                                  style={{
                                    top: "11px",
                                    left: `${parseInt(percentage) < 4 ? "" : "-21px"
                                      }`,
                                    fontWeight: 500,
                                    fontSize: "10px",
                                  }}
                                >
                                  {percentage}%
                                </div>
                              </div>
                            )}
                          </Step>
                        </ProgressBar>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsAndVideo);
